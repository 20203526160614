@font-face {
    font-family: "Cambay";
    src: url(/public/fonts/Cambay-Regular.ttf);
    font-weight: normal
}
@font-face {
    font-family: "Cambay";
    src: url(/public/fonts/Cambay-Bold.ttf);
    font-weight: bold
}

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-size: 1vw;
    font-family: 'Cambay';
    font-weight: 700;
    color: #fff;
    background-color: #171616;
}

.help-ua-header {
    width:100%;
    height: 100vh;
    background: url(/public/image/ukrainian_graphics.png) left/auto 100% no-repeat,
                url(/public/image/ukrainian_graphics.png) right/auto 100% no-repeat,
                url(/public/image/woman_urkaine.png) right bottom/auto 85% no-repeat,
                #1A1A1A;
    font-size: 1vw;
}
.help-ua-logo {
    display: flex;
    justify-content: center;
    height: 12%;
    gap: 10px;
}
.logo-nobelbee {
    background: url(/public/image/logo_help.png) right/auto 45% no-repeat;
    width: 120px;
    height: 100%;
}
.logo-molodist {
    background: url(/public/image/molodist.png) left/auto 45% no-repeat;
    width: 100px;
    height: 100%;
}

.help-ua-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75%;
    text-align: center;
}

.help-ua-main-title {
    font-size: 3.33em;
    margin: 0;
    line-height: 1.52;
}

.help-ua-main-description {
    font-weight: 400;
    font-size: 1.25em;
    line-height: 1.33; 
}

.help-ua-download-subtitle {
    font-weight: 400;
    margin-bottom: 1.7%;
}
.help-ua-download-buttons {
    display: flex;
    justify-content: space-between;
    width: 57.7em;
    margin: 0 auto;
}
.help-ua-download-button {
    width: 15.36em;
    height: 4.74em;
    display: block; 
    
}

.help-ua_download-webapp {
    background: url(/public/image/webapp.svg) center/contain no-repeat;
}
.help-ua_download-apple {
    background: url(/public/image/apple.svg) center/contain no-repeat;
}
.help-ua_download-google {
    background: url(/public/image/google.svg) center/contain no-repeat;
}
.lang_eng {
    background: url(/public/image/eng.png) center/auto 40% no-repeat;
    height: 6%;
}

@media (max-width: 1024px) and (orientation: portrait) {
    .help-ua-header {
        font-size: 1.5vw;
        background: url(/public/image/ukrainian_graphics.png) right/auto 100% no-repeat,
        url(/public/image/woman_urkaine.png) right bottom/auto 60% no-repeat,
        #1A1A1A;
    }
    .help-ua-main-text {
        order: -1;
        margin-top: 15em;
    }
    .help-ua-download-button {
        font-size: 1.1em;
    }
    .help-ua-text {
        font-size: 1.2em;
    }
    .help-ua-main-description {
        font-size: 2em;
        padding: 0 1.5em;
    }
    .help-ua-download-subtitle {
        font-size: 2em;
    }
}
.help-ua-project {
    height: 31.25em;
    background: url(/public/image/projectbg.jpg) center/cover,
                rgba(0, 0, 0, .7);
    background-blend-mode: multiply;
}

@media (max-width: 768px){
    .help-ua-project {
        height: 55em;
    }
}

.help-ua-partner {
    height: 29.16em;
    padding-top: 4em;
    text-align: center;
    background: #1A1A1A;
}
.help-ua-partner-description {
    font-weight: 400;
    font-size: 1.25em;
}
.partner-logo {
    display: block;
    background-size: 100%;
    margin: 3.7% auto;
    width: 13.125em;
    height: 8em;
}
@media (max-width: 768px){
    .help-ua-partner {
        height: 25em;
        font-size: 2.5em;
    }
    .help-ua-partner-description {
        font-size: 1.15em;
    }
}

.help-ua-support {
    margin-top: 4.6875em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.help-ua-subtitle {
    margin: 0;
    font-size: 0.93em;
    line-height: 2.5;
}
.help-ua-title {
    margin: 0 0 .68em 0;
    line-height: 1.27;
    font-size: 2.29em;
}
.help-ua-description {
    font-weight: 400;
    font-size: 1.041em;
}
.help-ua-support-text {
    width: 41.66%;
    padding: 6.823em 4.53em 3em 7.19em;
}
.help-ua-support-g1 {
    display: flex;
    justify-content: space-between;
    width: 58.33%;
}

.help-ua-support-g2 {
    display: flex;
    justify-content: space-between;
    margin-top: 3.125em;
    width: 100%;
}
.support-img1,.support-img2 {
    width: 48%;
}
.support-img3 {
    width: 39.5%;
}
.support-img4 {
    width: 58.33%;
}

@media (max-width: 768px){
    .help-ua-support-text {
        width: 100%;
        order: -1;
        font-size: 2.5em;
        padding: 2em;
    }
    .help-ua-support-g1 {
        width: 100%;
    }
    .support-img4 {
        width: 56.8%;
    }
}

.help-ua-stand {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 6.25em;
}

.help-ua-stand-g1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 39.3%;
    gap: 3.07em;
}
.help-ua-stand-g2 {
    display: flex;
    flex-direction: column;
    width: 28%;
    gap: 3.07em;
}
.help-ua-stand-g3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 28%;
    gap: 3.07em;
}
.help-ua-stand-text {
    padding: 6.823em 4.53em 0 7.19em;
    height: 30.7915em;
}
.img-with-girl {
    position: relative;
}
.sitting-girl {
    position: absolute;
    z-index: 10;
    width: 15.57em;
    height: 15.57em;
    left: -5.5em;
    bottom: 0.57em;
    background: url(/public/image/girl.svg) center/contain no-repeat;
}
@media (max-width: 768px){
    .help-ua-stand-text {
        width: 100%;
        height: 16em;
        font-size: 2.5em;
        padding: 2em;
    }
    .help-ua-stand-g1 {
        width: 100%;
        margin-bottom: 3.07em;
    }
    .help-ua-stand-g2,
    .help-ua-stand-g3 {
        width: 48%;
    }
    .sitting-girl {
        display: none;
    }
}
.stand-img {
    width: 100%;
}

.help-ua-donate {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 8.33em;
    height: 53.54em;
    background: url(/public/image/donatebg.jpg) center/cover no-repeat,
                rgba(0, 0, 0, .73);
    background-blend-mode: multiply;
    text-align: center;
}
.help-ua-donate-title {
    font-size: 3.125em;
    margin: 0 0 0.6em 0;
}

.help-ua-footer {
    display: flex;
    justify-content: center;
    margin-top: 7.3%;
    height: 6.25em;
    background: rgba(0, 0, 0, .73);
}
.help-ua-footer-midtext {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3em;
    flex-shrink: 0;
    width: 70%;
    font-weight: 400;
}
.help-ua-footer-midtext>a {
    color: white;
    text-decoration: none;
}

@media (max-width: 768px){
    .help-ua-footer {
        font-size: 2em;
    }
    .donate-bottom {
        font-size: 1.4em;
    }
}